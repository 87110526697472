<template>
  <div>
    <p
      class="text-center font-bold"
    >
      {{ t('auth.extranet_register.form.step_title') }} {{ currentStep }} : {{ t('auth.extranet_register.form.choose_establishment') }}
    </p>

    <app-card-item
      v-for="(context, i) in localContexts"
      :key="i"
      class="p-3 my-4 cursor-pointer border-4 border-white"
      :class="isCurrentContext(context.name) ? 'border-theme-500' : ''"
      @click="selectContext(context)"
    >
      <div class="flex">
        <div class="flex mx-2 text-center items-center">
          <app-icon
            library="coolicon"
            :name="isCurrentContext(context.name) ? 'checkbox_square' : 'checkbox'"
            size="text-2xl"
            :class="isCurrentContext(context.name) ? 'text-theme-500' : ''"
          />
        </div>

        <div>
          <p class="font-bold text-2xl">
            {{ t(`enums.ContextsEnum.${toUpper(context.name)}`) }}
          </p>

          <p>
            {{ getAttributeTranslation(context.description) }}
          </p>
        </div>
      </div>
    </app-card-item>

    <form-group
      type="hidden"
      name="context"
    />

    <app-button
      type="button"
      feature="next"
      :disabled="!isContextSelected"
      display-classes="flex"
      class="py-3 px-6 min-w-fit ml-auto max-w-8/10 mt-8 flex-row-reverse gap-2"
      :label="t('common.actions.next')"
      :hide-icon="false"
      @click="next"
    />
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { isNil, toUpper } from 'lodash'

import useLocale from '@shared/hooks/locale'
import useRegisteringContexts from '@shared/hooks/registeringContexts'
import AppButton from '@shared/components/ui/AppButton.vue'
import AppCardItem from '@shared/components/ui/card/AppCardItem.vue'
import AppIcon from '@shared/components/ui/AppIcon.vue'
import FormGroup from '@shared/components/form/FormGroup.vue'

defineProps({
  // JSON API resource used to
  // populate the form fields
  resource: {
    type: Object,
    default: () => ({}),
  },
  // Get the current step at the time.
  currentStep: {
    type: Number,
    required: true,
  },
})

const emits = defineEmits([
  'next',
  'setContext',
])

const { t } = useI18n()
const { getAttributeTranslation } = useLocale()

// ========== CONTEXT ==========

const { contexts } = useRegisteringContexts()

const localContexts = ref(contexts)

const selectedContext = ref(null)

// Check if selectedContext is current context.
const isCurrentContext = (value) => {
  if (isContextSelected.value) {
    return selectedContext.value.name === value
  }

  return false
}

const isContextSelected = computed(() => (
  !isNil(selectedContext.value)
))

function selectContext(context) {
  selectedContext.value = context
  emits('setContext', context)
}

// ========== STEPS ==========

function next() {
  emits('next')
}
</script>
